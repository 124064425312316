<template>
	<r-data-list
		:headdata="headdata"
		:listdata="filteredList"
		:opt="opts"
		v-if="loaded"
		:searchfilter="searchfilter"
		v-model:qu="q"
		><template v-slot:nodatain>
			<r-select
				v-model="selectedcat"
				label="Branş"
				:data="branchList.data"
				txt="title"
				val="id"
				width="30%"
				style="color: rgb(17, 17, 17)"
			>
				<template v-slot:itemtxt="{ data }">
					{{ data.id + '_' + data.title }}
				</template>
			</r-select>
		</template>
		<template v-slot:titleextrabuttons>
			<r-select
				v-model="selectednote"
				label="Not Durumu"
				:data="noteSelect"
				txt="txt"
				val="val"
				width="30%"
		/></template>
		<template v-slot:titleextrabuttonsafter>
			<r-btn bgcolor="#55110d" @click="openCats">Ders Notu Kategorileri</r-btn>
		</template>
		<template v-slot:title>
			<r-flex align="flex-start center">
				<r-select
					v-model="selectedcat"
					label="Branş"
					:data="branchList.data"
					txt="title"
					val="id"
					width="30%"
				>
					<template v-slot:itemtxt="{ data }">
						{{ data.id + '_' + data.title }}
					</template>
				</r-select>
				<h3>Ders Notları</h3>
			</r-flex>
		</template>
		<template v-slot:col6="{ datarow }">
			<span class="r-check-ok small" v-if="datarow.isActive === true"></span>
			<span class="r-check-no small" v-else></span>
		</template>
	</r-data-list>
	<r-panel
		:title="'Yeni Ders Notu Ekle'"
		v-if="newPanel"
		v-model:isactive="newPanel"
		classes="r-flx-dw-80"
		pos="center"
	>
		<r-flex>
			<r-checkbox v-model="newData.isActive" label="Aktif mi?" />
		</r-flex>
		<r-flex>
			<r-select
				v-model="newData.categoryId"
				label="Branş"
				:data="branchList.data"
				txt="title"
				val="id"
				class="r-mb-16"
			/>
			<r-select
				v-model="newData.categoryTypeId"
				label="Kategori"
				:data="noteCats.data"
				txt="name"
				val="id"
				class="r-mb-16"
			/>
			<r-input v-model="newData.name" label="Ders Notu Adı" class="r-mb-16" />
			<r-flex>
				<r-flex flx="4" class="r-pr-32">
					<r-select
						v-model="newData.fassiculeCount"
						label="Fasikül Sayısı"
						:data="fassiculeCount"
						@change="fassiculeChange"
					/>
				</r-flex>
				<r-flex flx="8">
					<r-flex
						v-for="(pages, i, k) in newData.pages"
						:key="i"
						align="center"
						class="r-mb-16"
					>
						<r-flex flx="2" align="center">
							<strong>{{ 'Fasikül ' + (k + 1) + ' : ' }}</strong>
						</r-flex>
						<r-flex flx="10" align="center">
							<r-input
								v-for="(inp, j) in pages"
								:key="j"
								v-model="newData.pages[i][j]"
								:label="(j === 0 ? 'Başlangıç' : 'Bitiş') + ' Sayfa No'"
								width="45%"
							/>
						</r-flex>
					</r-flex>
				</r-flex>
			</r-flex>
			<r-flex>
				<r-btn bgcolor="#15880d" @click="saveNew" :disabled="canSaveNote"
					>Kaydet</r-btn
				>
			</r-flex>
		</r-flex>
	</r-panel>
	<r-panel
		title="Ders Notu Kategorileri"
		v-if="catsPanel"
		v-model:isactive="catsPanel"
		pos="center"
		width="50%"
		classes="r-flx-dw-80"
	>
		<r-data-list
			:headdata="catsheaddata"
			:listdata="catsListData.data"
			:opt="catsopts"
			v-if="catsloaded"
			:searchfilter="searchcatsfilter"
			v-model:qu="qc"
		/>
	</r-panel>
</template>
<script>
	import { onBeforeMount, ref, computed, watch } from 'vue'
	import { useStore } from 'vuex'
	import { trToUpper } from '@/mix/global'
	export default {
		setup() {
			const store = useStore()
			const listData = ref({})
			const catsListData = ref({})
			const branchList = ref({})
			const noteCats = ref({})
			const loaded = ref(false)
			const catsloaded = ref(false)
			const selectedcat = ref(null)
			const selectedAddCat = ref(null)
			const newPanel = ref(false)
			const newData = ref({})
			const fassiculeCount = ref([1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
			const catsPanel = ref(false)
			const q = ref('')
			const qc = ref('')
			const selectednote = ref(null)
			const noteSelect = ref([
				{ txt: 'Tümü', val: null },
				{ txt: 'Aktif', val: true },
				{ txt: 'Pasif', val: false },
			])
			const searchfilter = v => {
				return (
					trToUpper(v.name).includes(trToUpper(q.value)) ||
					trToUpper(v.categoryTypeName).includes(trToUpper(q.value))
				)
			}
			const selectfilter = v => {
				return v.isActive === selectednote.value
			}

			const searchcatsfilter = v => {
				return (
					trToUpper(v.name).includes(trToUpper(qc.value)) ||
					trToUpper(v.title).includes(trToUpper(qc.value)) ||
					trToUpper(v.description).includes(trToUpper(qc.value))
				)
			}
			const canSaveNote = computed(() => {
				if (
					newData.value.categoryId !== null &&
					newData.value.categoryId !== undefined &&
					newData.value.categoryId !== '' &&
					newData.value.categoryTypeId !== null &&
					newData.value.categoryTypeId !== undefined &&
					newData.value.categoryTypeId !== '' &&
					newData.value.name !== null &&
					newData.value.name !== undefined &&
					newData.value.name !== '' &&
					newData.value.fassiculeCount !== null &&
					newData.value.fassiculeCount !== undefined &&
					newData.value.fassiculeCount !== '' &&
					controlFassicules.value
				)
					return false
				else return true
			})
			const controlFassicules = computed(() => {
				var isok = true
				if (newData.value.pages !== undefined) {
					Object.keys(newData.value.pages).forEach(k => {
						newData.value.pages[k].forEach(val => {
							if (val === undefined || val === '') isok = false
						})
					})
				}
				return isok
			})
			const filteredList = computed(() => {
				if (selectedcat.value !== null && listData.value.data) {
					if (selectednote.value === null) {
						return listData.value.data.filter(searchfilter)
					} else {
						return listData.value.data.filter(searchfilter).filter(selectfilter)
					}
				} else {
					return []
				}
			})

			const headdata = computed(() => {
				return [
					{
						title: 'ID',
						style: { width: '5%' },
						key: 'id',
					},
					{
						title: 'Adı',
						style: { width: '35%' },
						key: 'name',
					},
					{
						title: 'Tür',
						style: { width: '20%', justifyContent: 'center' },
						key: 'categoryTypeName',
					},
					{
						title: 'Fasikül Sayısı',
						style: { width: '10%', justifyContent: 'center' },
						key: 'fassiculeCount',
					},
					{
						title: 'Sayfa No',
						style: { width: '20%' },
						key: 'pageNo',
					},
					{
						title: 'Aktif mi?',
						style: { width: '10%', justifyContent: 'center' },
						key: 'isActive',
					},
				]
			})
			const opts = ref({
				title: 'Ders Notları',
				nodatatxt: () => {
					return selectedcat.value === null
						? 'Lütfen branş seçiniz'
						: listData.value.data && listData.value.data.length === 0
						? 'Sonuç bulunamadı'
						: ''
				},
				buttons: {
					style: { width: '15%' },
					edit: {
						show: true,
						type: 'out',
						func: d => {
							editFunc(d)
						},
					},
					delete: {
						show: true,
						func: d => {
							deleteFunc(d)
						},
					},
					add: {
						show: true,
						type: 'out',
						func: d => {
							addFunc(d)
						},
					},
				},
			})
			const catsheaddata = computed(() => {
				return [
					{
						title: 'Adı',
						style: { width: '20%' },
						key: 'name',
						valid: 'required',
					},
					{
						title: 'Başlık',
						style: { width: '30%', justifyContent: 'center' },
						key: 'title',
						type: 'textarea',
					},
					{
						title: 'Açıklama',
						style: { width: '35%', justifyContent: 'center' },
						key: 'description',
						type: 'textarea',
					},
				]
			})
			const catsopts = ref({
				title: 'Ders Notu kategorileri',
				buttons: {
					style: { width: '15%' },
					edit: {
						show: true,
						type: 'in',
						savefunc: d => {
							editCatsFunc(d)
						},
					},
					delete: {
						show: true,
						func: d => {
							deleteCatsFunc(d)
						},
					},
					add: {
						show: true,
						type: 'in',
						func: d => {
							addCatsFunc(d)
						},
					},
				},
			})
			const getList = async () => {
				let list = await store.dispatch('getDatas', {
					api: 'notes/getnotesbycategory',
					params: {
						categoryid: selectedcat.value,
						isActive: false,
					},
				})
				return list
			}
			const getBranchList = async () => {
				let list = await store.dispatch('getDatas', {
					api: 'categories/getsubcategories',
					params: {
						pid: 0,
					},
				})
				return list
			}
			const getCatsList = async () => {
				let list = await store.dispatch('getDatas', {
					api: 'notes/getnotecategories',
				})
				return list
			}
			const addFunc = async () => {
				newData.value = {}
				newData.value.pages = {}
				newData.value.categoryId = selectedcat.value
				newPanel.value = true
			}
			const editFunc = async d => {
				newData.value = d.row
				let f = d.row.pageNo.split('#')
				newData.value.pages = {}
				f.forEach((v, i) => {
					newData.value.pages['f' + i] = v.split('-')
				})
				newPanel.value = true
			}
			const deleteFunc = async d => {
				await store.dispatch('postDatas', {
					api: 'notes/deletenote',
					data: { id: parseInt(d.row.id) },
				})
				listData.value = await getList()
			}
			const addCatsFunc = async d => {
				await store.dispatch('postDatas', {
					api: 'notecategories/addnotecategory',
					data: d,
				})
				catsListData.value = await getCatsList()
				noteCats.value = await getCatsList()
			}
			const editCatsFunc = async d => {
				d.id = parseInt(d.id)
				await store.dispatch('postDatas', {
					api: 'notecategories/addnotecategory',
					data: d,
				})
				catsListData.value = await getCatsList()
				noteCats.value = await getCatsList()
			}
			const deleteCatsFunc = async d => {
				await store.dispatch('postDatas', {
					api: 'notecategories/deletenotecategory',
					data: { id: parseInt(d.row.id) },
				})
				catsListData.value = await getCatsList()
				noteCats.value = await getCatsList()
			}
			const fassiculeChange = d => {
				let ol = Object.keys(newData.value.pages).length

				if (ol > d) {
					for (let j = d; j < ol; j++) {
						delete newData.value.pages['f' + j]
					}
				} else {
					for (let i = ol; i < d; i++) {
						newData.value.pages['f' + i] = ['', '']
					}
				}
			}

			const saveLine = async d => {
				await store.dispatch('postDatas', {
					api: 'categories/addcategory',
					data: d,
				})
				listData.value = await getList()
			}
			const saveCatsLine = async d => {
				await store.dispatch('postDatas', {
					api: 'categories/addcategory',
					data: d,
				})
				listData.value = await getList()
			}
			const saveNew = async () => {
				var str = ''
				var ln = Object.keys(newData.value.pages).length
				Object.keys(newData.value.pages).forEach((p, i) => {
					str =
						str +
						newData.value.pages[p][0].padStart(3, '0') +
						'-' +
						newData.value.pages[p][1].padStart(3, '0')
					str += i === ln - 1 ? '' : '#'
				})
				newData.value.pageNo = str
				delete newData.value.pages
				await store.dispatch('postDatas', {
					api: 'notes/addnote',
					data: newData.value,
				})
				newPanel.value = false
				listData.value = await getList()
			}
			const openCats = async () => {
				catsPanel.value = true
				catsListData.value = await getCatsList()
				catsloaded.value = true
			}
			watch(
				() => selectedcat.value,
				async () => {
					listData.value = await getList()
				}
			)
			onBeforeMount(async () => {
				branchList.value = await getBranchList()
				noteCats.value = await getCatsList()
				loaded.value = true
			})
			return {
				headdata,
				opts,
				catsheaddata,
				catsopts,
				filteredList,
				saveLine,
				addFunc,
				loaded,
				selectedcat,
				branchList,
				newPanel,
				selectedAddCat,
				newData,
				noteCats,
				fassiculeCount,
				fassiculeChange,
				saveNew,
				q,
				searchfilter,
				qc,
				searchcatsfilter,
				catsPanel,
				openCats,
				catsloaded,
				saveCatsLine,
				catsListData,
				noteSelect,
				selectednote,
				canSaveNote,
			}
		},
	}
</script>
